import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { ISection, SectionTypes } from '../../types';
import Section from '../Section';
import {
  ContactInfoViewModel,
  ContactSectionViewModel,
} from '../../../../service-page-view-model/contact-section-view-model/contactSectionViewModel';
import SectionTitle from '../SectionTitle/SectionTitle';
import SectionText from '../SectionText/SectionText';
import settingsParams from '../../settingsParams';
import { classes, vars } from './Contact.st.css';
import { useSettings } from '@wix/tpa-settings/react';

type ContactComponentProps = {
  viewModel?: ContactSectionViewModel;
  section: ISection;
};

const Contact: React.FC<ContactComponentProps> = ({ viewModel }) => {
  const { t } = useTranslation();
  const settings = useSettings();

  const contactSectionTitle = () => {
    const contactDetailsTitle =
      settings.get(settingsParams.contactDetailsTitleText) ||
      t('app.settings.sections.contact.default.contact-details-title.label');
    return (
      <SectionTitle title={contactDetailsTitle} dataHook="contact-title" />
    );
  };

  const businessPhoneNumber = () => {
    return settings.get(settingsParams.businessPhoneNumberVisibility) &&
      viewModel?.phone ? (
      <div>
        <SectionText text={viewModel.phone} dataHook="business-phone-number" />
      </div>
    ) : null;
  };

  const businessEmail = () => {
    return settings.get(settingsParams.businessEmailVisibility) &&
      viewModel?.email ? (
      <div>
        <SectionText text={viewModel.email} dataHook="business-email" />
      </div>
    ) : null;
  };

  const businessAddress = () => {
    return settings.get(settingsParams.businessAddressVisibility) &&
      viewModel?.address ? (
      <div>
        <SectionText text={viewModel.address} dataHook="business-address" />
      </div>
    ) : null;
  };

  const businessLocationAddress = (address) => {
    return address && settings.get(settingsParams.businessAddressVisibility) ? (
      <SectionText text={address} dataHook="location-address" />
    ) : null;
  };

  const businessLocationPhone = (phone) => {
    return settings.get(settingsParams.businessPhoneNumberVisibility) &&
      phone ? (
      <SectionText text={phone} dataHook="location-phone-number" />
    ) : null;
  };

  const businessLocationEmail = (email) => {
    return settings.get(settingsParams.businessEmailVisibility) && email ? (
      <SectionText text={email} dataHook="location-email" />
    ) : null;
  };

  const businessLocationName = (name) => {
    return name && settings.get(settingsParams.locationNameVisibility) ? (
      <SectionText
        className={classes.locationName}
        text={name}
        dataHook="location-name"
      />
    ) : null;
  };

  const businessLocationInfo = (contactInfo: ContactInfoViewModel) => {
    return (
      <li className={classes.businessLocation} key={contactInfo.name}>
        {businessLocationName(contactInfo.name)}
        {businessLocationAddress(contactInfo.address)}
        {businessLocationPhone(contactInfo.phone)}
        {businessLocationEmail(contactInfo.email)}
      </li>
    );
  };

  const multiLocationContacts = () => {
    const contactInfo = viewModel?.contactInfo;
    const numberOfLocations = Number(contactInfo?.length);
    const shouldShowMultiLocation = numberOfLocations > 0;
    const maxNumberOfColumns = Math.min(numberOfLocations, 3);
    return shouldShowMultiLocation ? (
      <Section sectionType={SectionTypes.CONTACT}>
        <div data-hook="contact-details-wrapper">
          {contactSectionTitle()}
          <ul
            className={classes.businessLocations}
            style={{ [vars.maxNumberOfColumns]: maxNumberOfColumns }}
          >
            {contactInfo?.map(businessLocationInfo)}
          </ul>
        </div>
      </Section>
    ) : null;
  };

  return viewModel?.contactInfo?.length ? (
    multiLocationContacts()
  ) : viewModel?.address || viewModel?.email || viewModel?.phone ? (
    <Section sectionType={SectionTypes.CONTACT}>
      <div data-hook="contact-details-wrapper">
        {contactSectionTitle()}
        {businessPhoneNumber()}
        {businessEmail()}
        {businessAddress()}
      </div>
    </Section>
  ) : null;
};

export default Contact;
