import React from 'react';
import { Spinner } from 'wix-ui-tpa/cssVars';
import { classes } from './Loader.st.css';

export const Loader = () => {
  return (
    <div className={classes.root} data-hook="scheduling-loader">
      <Spinner diameter={24} />
    </div>
  );
};
