import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { useState, useEffect, RefObject } from 'react';

export function useScrollBase(containerRef: RefObject<HTMLElement>): number {
  const [scrollBase, setScrollBase] = useState(0);
  const { isSSR } = useEnvironment();
  const { experiments } = useExperiments();
  useEffect(() => {
    if (!isSSR && experiments.enabled('specs.bookings.useScrollBase')) {
      let observedContainer: HTMLElement | null = null;
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting && observedContainer) {
          const rect = observedContainer.getBoundingClientRect();
          setScrollBase(rect.top + window.pageYOffset);
        }
      });

      if (containerRef.current) {
        observedContainer = containerRef.current;
        const rect = observedContainer.getBoundingClientRect();
        setScrollBase(rect.top + window.pageYOffset);
        observer.observe(observedContainer);
      }
      return () => {
        if (observedContainer) {
          observer.unobserve(observedContainer);
        }
      };
    }
  }, [containerRef, isSSR]);

  return scrollBase;
}
