import React from 'react';
import { SectionTypes, AlignmentOptions } from '../../types';
import BookButton from '../BookButton/BookButton';
import { st, classes } from './Section.st.css';
import { Divider } from 'wix-ui-tpa/cssVars';
import { useGlobalAlignment } from '../useGlobalAlignment';

interface SectionProps {
  withBookButton?: boolean;
  sectionType: SectionTypes;
  buttonAlignment?: AlignmentOptions;
  childrenAlignment?: AlignmentOptions;
  className?: string;
  availability?: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({
  children,
  sectionType,
  withBookButton,
  className,
  buttonAlignment,
  childrenAlignment,
  availability,
  ...rest
}) => {
  const globalAlignment = useGlobalAlignment();

  return (
    <>
      <div
        className={st(classes.root, {
          withBookButton: !!withBookButton,
          alignment: childrenAlignment || globalAlignment,
          extendedSpacing: sectionType === SectionTypes.GALLERY,
        })}
        {...rest}
        key={sectionType}
        data-section-type={sectionType}
      >
        {sectionType !== SectionTypes.DETAILS &&
          sectionType !== SectionTypes.GALLERY && (
            <Divider data-hook="section-divider" className={classes.divider} />
          )}
        {children && (
          <div
            data-hook={`children-${sectionType}`}
            className={st(classes.childrenWrapper, {}, className)}
          >
            {children}
          </div>
        )}
        {availability && (
          <div
            className={st(classes.availability, {
              alignment: buttonAlignment || globalAlignment,
            })}
          >
            {availability}
          </div>
        )}
        {withBookButton && (
          <div
            data-hook="book-button-wrapper"
            className={st(
              classes.buttonWrapper,
              {
                alignment: buttonAlignment || globalAlignment,
                spaceBetweenSections: !availability,
              },
              className,
            )}
          >
            <BookButton
              dataHook={`book-button-${sectionType}`}
              renderedAt={sectionType}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Section;
