import React from 'react';
import { Text } from 'wix-ui-tpa/cssVars';
import { st, classes } from './SessionDetails.st.css';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../../BookingServicePage/settingsParams';
import { DailySession } from '../../../service-page-view-model/scheduling-section-view-model/schedulingSectionViewModel';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import LocationIcon from '../../../assets/icons/Location.svg';
import { useCurrentLocation } from '../useCurrentLocation';
import { All_LOCATIONS_ID } from '../../../service-page-view-model/scheduling-location-view-model/schedulingLocationViewModel';

export const SessionDetails: React.FC<DailySession> = ({
  startTime,
  durationText,
  durationAriaLabel,
  staffName,
  locationName,
}) => {
  const settings = useSettings();
  const { isRTL, isMobile } = useEnvironment();
  const staffVisibility = settings.get(settingsParams.scheduleStaffVisibility);
  const durationVisibility = settings.get(
    settingsParams.scheduleDurationVisibility,
  );
  const currentLocation = useCurrentLocation();
  const showLocationName = currentLocation === All_LOCATIONS_ID;
  const showDuration = () =>
    durationVisibility ? (
      <>
        <div
          dir={isRTL ? 'rtl' : undefined}
          className={classes.detailWrapper}
          aria-hidden
        >
          <Text className={classes.duration} data-hook="duration-text">
            {durationText}
          </Text>
        </div>
        <div className={classes.srOnly} data-hook="duration-aria-label">
          {durationAriaLabel}
        </div>
      </>
    ) : null;
  const showStaff = () =>
    staffVisibility && staffName ? (
      <div className={classes.detailWrapper}>
        <Text className={classes.staff} data-hook="staff-name">
          {staffName}
        </Text>
      </div>
    ) : null;
  return (
    <div className={st(classes.root, { isRTL, isMobile })}>
      {showLocationName && locationName ? (
        <div className={classes.details}>
          <div className={classes.detailWrapper}>
            <Text
              className={classes.startTime}
              data-hook="start-time"
              tagName="time"
            >
              {startTime}
            </Text>
          </div>
          <div
            className={`${classes.detailWrapper} ${classes.locationWrapper}`}
          >
            <LocationIcon
              width="1em"
              height="1.4em"
              className={classes.locationIcon}
            />
            <Text className={classes.location} data-hook="location">
              {locationName}
            </Text>
          </div>
        </div>
      ) : (
        <Text
          className={classes.startTime}
          data-hook="start-time"
          tagName="time"
        >
          {startTime}
        </Text>
      )}
      <div className={classes.details}>
        {showDuration()}
        {showStaff()}
      </div>
    </div>
  );
};
